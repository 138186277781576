import { LoyaltyApplicationState } from "state/RootSchema";
import { toSingleLetterFromLowerCaseGender } from "./toSingleLetterFromLowerCaseGender";

import { post_SubmitMLTAApplication } from "services/online-application-service.openapi.zod";
import { z } from "zod";

const BodySchema = post_SubmitMLTAApplication.parameters.shape.body;
type LoyaltySubmissionRequestBody = z.infer<typeof BodySchema>;
type BackendTiers = z.infer<typeof BodySchema.shape.tierSelection>;

export function transformMLTASubmissionData(rootData: LoyaltyApplicationState): LoyaltySubmissionRequestBody {
    const data = rootData.application;

    const addressees = data.designatedSecondaryAddressee
        ? {
              hasSecondaryAddressee: data.designatedSecondaryAddressee,
              secondaryAddressee: {
                  firstName: data.secondaryAddressee?.firstName,
                  lastName: data.secondaryAddressee?.lastName,
                  phone: data.secondaryAddressee?.phone || undefined,
                  address: {
                      addressLine1: data.secondaryAddressee?.addressLine1,
                      addressLine2: data.secondaryAddressee?.addressLine2 || undefined,
                      city: data.secondaryAddressee?.city,
                      state: data.secondaryAddressee?.state,
                      zipCode: data.secondaryAddressee?.zipCode,
                  },
              },
          }
        : {
              hasSecondaryAddressee: data.designatedSecondaryAddressee,
          };

    const additionalData = [
        {
            key: "applicantType",
            value: data.applicantType,
        },
    ];

    return {
        applicationID: data.applicationID,
        policyNumber: data.policyNumber,

        faceAmount: data.selectedCoverageAmount,
        modalPremiumAmount: data.selectedCoveragePremium,

        applicant: {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.dateOfBirth,
            phone: data.phone,
            phoneType: data.phoneType,
            address: {
                addressLine1: data.addressLine1,
                addressLine2: data.addressLine2 || undefined,
                city: data.city,
                state: data.state,
                zipCode: data.zipCode,
                country: "USA",
            },
            //Instructions have been to default to Male gender when the gender is unknown as provided by findOffer
            gender: rootData.gender ? toSingleLetterFromLowerCaseGender(rootData.gender) : "M",
            keyCode: rootData.application.keyCode,
            aaaMemberNumber: rootData.application.aaaMemberNumber,
            email: data.email,

            memberJoinDate: rootData.memberSince,
            membershipLevel: rootData.membershipLevel,
        },

        beneficiaries: [
            {
                firstName: data.beneficiary.firstName,
                middleInitial: data.beneficiary.middleInitial || undefined,
                lastName: data.beneficiary.lastName,
                relationship: data.beneficiary.relationship,
                percentage: data.beneficiary.percentage,
                beneficiaryType: "primary",
            },
        ],

        ...addressees,

        paymentToken: data.paymentToken,
        acceptanceConfirmed: data.agreeToConsent,
        campaign: {
            campaignType: "CAMPAIGNTYPE_DIRECTMAIL",
            productCode: rootData.clubSpecificData.planCode,
            clubCode: rootData.clubSpecificData.clubCode,
            leadSource: rootData.campaign.leadSource || undefined,
            campaignName: "DIR",
            campaignCode: rootData.campaign.campaignCode,
        },
        offerPurl: rootData.campaign.offerPurl,
        signedTimestamp: new Date().toISOString(),
        applicationSubmissionTimestamp: new Date().toISOString(),

        additionalData,

        // NOTE: we cast the type here specifically instead of verifying because from the frontend's perspective, the tier is
        // supposed to be a dynamic value which may vary between campaigns. Strict type checking at any point then would make
        // the implementation inflexible. The backend services should likely also follow suite and treat tier as
        tiers: data.coverageOptions.map((option) => option.tier) as BackendTiers[],
        tierSelection: data.selectedCoverageTier as BackendTiers, //NOSONAR
        coverageTypeSelection: data.selectedCoverageType,
    };
}
