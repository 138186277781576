import { z } from "zod";

export const HeaderContentSchema = z.object({
    productName: z.string(),
    productNameHtml: z.string().optional(),
    headerSubtitleText: z.string(),
    headerHelpText: z.string(),
    phoneNumberText: z.string(),
});
export type HeaderContent = z.infer<typeof HeaderContentSchema>;

export const FooterContentSchema = z.object({
    footerHtml: z.string(),
    footnote1: z.string().optional(),
    footnote2: z.string().optional(),
    footnote3: z.string().optional(),
    footnote4: z.string().optional(),
    footnote5: z.string().optional(),
});
export type FooterContent = z.infer<typeof FooterContentSchema>;

const CoverageSelectionLabelsSchema = z.object({
    selectedCoverageAmountLabel: z.string(),
    selectedTierLabel: z.string(),
    selectedPremiumAmountLabel: z.string(),
});

export const WelcomeContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelBodyHtml: z.string(),
    pageTitle: z.string(),
    // loyalty does not use the left panel footer html
    leftPanelFooterHtml: z.string().optional(),
    invitationCodeHelpText: z.string(),
    invitationCodeFormatHelpHtml: z.string(),
    invitationCodeHelpfulHintHtml: z.string(),
    formThumbnailImageUrl: z.string(),

    nextLabel: z.string(),

    invitationCodeRequiredErrorMessage: z.string(),
    invitationCodeMinLengthErrorMessage: z.string(),
    invitationCodeFormatErrorMessage: z.string(),
    invitationNotFoundErrorMessage: z.string(),
    invitationCodeFirstCharErrorMessageHtml: z.string(),
    invitationCodeSecondCharErrorMessageHtml: z.string(),
});

export const QuoteStartContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelTitleWithName: z.string(),
    leftPanelBodyHtml: z.string(),
    pageTitle: z.string(),
    primaryUserPopoverText: z.string(),

    spouseSelectLabel: z.string(),
    dateOfBirthLabel: z.string(),
    genderLabel: z.string(),
    stateLabel: z.string(),
    zipCodeLabel: z.string(),
    emailLabel: z.string(),

    stateDisqualifiedTitle: z.string(),
    stateDisqualifiedHtml: z.string(),
    ageDisqualifiedTitle: z.string(),
    ageDisqualifiedHtml: z.string(),

    // Content unnecessary for loyalty
    nicotineQuestion: z.string().optional(),

    previousLabel: z.string(),
    nextLabel: z.string(),

    invalidDateErrorMessage: z.string(),
    requiredFieldErrorMessage: z.string(),
    requiredSelectErrorMessage: z.string(),
    zipCodeLengthErrorMessage: z.string(),
    invalidZipCodeCharactersMessage: z.string(),
    emailMaxLength: z.string(),
    validEmail: z.string(),
});
export type QuoteStartContent = z.infer<typeof QuoteStartContentSchema>;

export const QuoteResultsContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelBodyHtml: z.string(),

    pageTitle: z.string(),
    mainContentTitle: z.string(),
    quoteSummaryGreetingTemplate: z.string(),
    coverageOptionsLabel: z.string(),
    monthlyPaymentLabel: z.string(),

    coverageForTravelAccidentsSectionHeader: z.string(),
    benefitAirlineLabelHtml: z.string(),
    benefitCommonCarrierLabelHtml: z.string(),
    benefitHospitalLabelHtml: z.string(),
    benefitAtHomeRecuperationLabelHtml: z.string(),
    benefitEmergencyLabelHtml: z.string(),
    benefitLossOfLifeLabelHtml: z.string(),
    coverageForOtherAccidentSectionHeader: z.string(),
    premiumsSectionHeader: z.string(),
    individualPlanLabelHtml: z.string(),
    familyPlanLabelHtml: z.string(),

    previousLabel: z.string(),
    nextLabel: z.string(),

    coverageOptionRequiredErrorMessage: z.string(),
    offerStateMismatchWarningMessage: z.string(),
});
export type QuoteResultsContent = z.infer<typeof QuoteResultsContentSchema>;

export const PersonalInfoContentSchema = z
    .object({
        leftPanelTitle: z.string(),
        leftPanelBodyHtml: z.string(),
        pageTitle: z.string(),
        notYouMessage: z.string(),
        stateDisqualifiedTitle: z.string(),
        stateDisqualifiedText: z.string(),

        applicantNameLabel: z.string(),
        applicantFirstNameLabel: z.string(),
        applicantMiddleInitialLabel: z.string(),
        applicantLastNameLabel: z.string(),
        dateOfBirthLabel: z.string(),
        genderLabel: z.string(),
        streetAddressLabel: z.string(),
        addressLine1PlaceholderText: z.string(),
        addressLine2PlaceholderText: z.string(),
        cityLabel: z.string(),
        stateLabel: z.string(),
        zipCodeLabel: z.string(),
        phoneNumberLabel: z.string(),
        phoneTypeLabel: z.string(),
        emailLabel: z.string(),
        heightLabel: z.string(),
        heightFeetUnitLabel: z.string(),
        heightInchesUnitLabel: z.string(),
        weightLabel: z.string(),
        weightPoundsUnitLabel: z.string(),
        replacementPolicySentenceLabel: z.string(),
        replacementCompanyLabel: z.string(),
        replacementPolicyLabel: z.string(),
        aaaMemberNumberLabel: z.string(),

        aaaMemberNumberToolTipImageUrl: z.string(),
        aaaMemberNumberToolTipText: z.string(),

        previousLabel: z.string(),
        nextLabel: z.string(),

        invalidInputErrorMessage: z.string(),
        requiredFieldErrorMessage: z.string(),
        requiredSelectErrorMessage: z.string(),
        noSpecialCharsErrorMessage: z.string(),
        firstNameMaxLength: z.string(),
        middleInitialLength: z.string(),
        lastNameMaxLength: z.string(),
        addressLine1MaxLength: z.string(),
        addressLine2MaxLength: z.string(),
        cityMaxLength: z.string(),
        validPhone: z.string(),
        phoneLength: z.string(),
        validEmail: z.string(),
        emailMaxLength: z.string(),
        companyMaxLength: z.string(),
        policyNumberMaxLength: z.string(),
        weightMaxLength: z.string(),
        aaaMemberNumberLengthErrorMessage: z.string(),
    })
    .merge(CoverageSelectionLabelsSchema);
export type PersonalInfoContent = z.infer<typeof PersonalInfoContentSchema>;

export const BeneficiariesContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelBodyHtml: z.string(),
    pageTitle: z.string(),
    relationshipPopoverHtml: z.string(),
    percentagePopoverHtml: z.string(),

    previousLabel: z.string(),
    nextLabel: z.string(),

    invalidInputErrorMessage: z.string(),
    requiredFieldErrorMessage: z.string(),
    noSpecialCharsErrorMessage: z.string(),
    firstNameMaxLength: z.string(),
    middleInitialLength: z.string(),
    lastNameMaxLength: z.string(),
});
export type BeneficiariesContent = z.infer<typeof BeneficiariesContentSchema>;

export const AddresseeContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelBodyHtml: z.string(),
    pageTitle: z.string(),
    addresseePopoverHtml: z.string(),

    previousLabel: z.string(),
    nextLabel: z.string(),

    invalidInputErrorMessage: z.string(),
    requiredFieldErrorMessage: z.string(),
    requiredSelectErrorMessage: z.string(),
    noSpecialCharsErrorMessage: z.string(),
    zipCanOnlyBeNumbers: z.string(),
    firstNameMaxLength: z.string(),
    lastNameMaxLength: z.string(),
    addressLine1MaxLength: z.string(),
    addressLine2MaxLength: z.string(),
    cityMaxLength: z.string(),
    zipLength: z.string(),
    validPhone: z.string(),
    phoneLength: z.string(),
});
export type AddresseeContent = z.infer<typeof AddresseeContentSchema>;

export const HealthStatementContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelBodyHtml: z.string(),
    pageTitle: z.string(),

    nicotineQuestion: z.string(),
    severeMedicalQuestionHtml: z.string(),
    diagnosticTestingQuestion: z.string(),

    previousLabel: z.string(),
    nextLabel: z.string(),

    invalidInputErrorMessage: z.string(),
    requiredSelectErrorMessage: z.string(),
});
export type HealthStatementContent = z.infer<typeof HealthStatementContentSchema>;

export const PaymentInfoContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelBodyHtml: z.string(),
    pageTitle: z.string(),
    mainContentTitle: z.string(),
    validatedPaymentMessage: z.string(),
    productName: z.string(),
    supplementalPaymentAuthorizationHtml: z.string(),

    previousLabel: z.string(),
    nextLabel: z.string(),
    editPaymentButtonLabel: z.string(),

    submissionError: z.string(),
});
export type PaymentInfoContent = z.infer<typeof PaymentInfoContentSchema>;

export const ReviewContentSchema = z
    .object({
        leftPanelTitle: z.string(),
        leftPanelBodyHtml: z.string(),

        applicantInformationSectionHeader: z.string(),
        coverageSelectionSectionHeader: z.string(),
        beneficiaryInformationSectionHeader: z.string(),
        secondaryAddresseeSectionHeader: z.string(),
        replacementPolicySectionHeader: z.string(),
        statementOfHealthSectionHeader: z.string(),

        nicotineQuestion: z.string(),
        severeMedicalQuestionHtml: z.string(),
        diagnosticTestingQuestion: z.string(),

        previousLabel: z.string(),
        nextLabel: z.string(),
    })
    .merge(CoverageSelectionLabelsSchema);
export type ReviewContent = z.infer<typeof ReviewContentSchema>;

export const ConsentContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelBodyHtml: z.string(),
    consentLabel: z.string(),
    scrollBoxHtml: z.string(),
    fraudWarningHtml: z.string(),
    agreementHtml: z.string(),
    finalWarningHtml: z.string(),
    previousLabel: z.string(),
    nextLabel: z.string(),
    pageTitle: z.string().optional(),
    agentSignatureImageUrl: z.string().optional(),
    agentStatement: z.string().optional(),
    agentInfoHtml: z.string().optional(),
});
export type ConsentContent = z.infer<typeof ConsentContentSchema>;

export const ProcessingContentSchema = z.object({
    title: z.string(),
    bodyHtml: z.string(),
});

export const ApprovedContentSchema = z.object({
    title: z.string(),
    bodyHtml: z.string(),
    productName: z.string(),
});

export const CheckBackContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelBodyHtml: z.string(),
    title: z.string(),
    bodyHtml: z.string(),
});

export const OopsSorryContentSchema = z.object({
    leftPanelTitle: z.string(),
    leftPanelBodyHtml: z.string(),
    title: z.string(),
    bodyHtml: z.string(),
});

export const DeclinedContentSchema = z.object({
    title: z.string(),
    bodyHtml: z.string(),
});

export const UnableToFinishContentSchema = z.object({
    title: z.string(),
    bodyHtml: z.string(),
});

export const SessionExpiredContentSchema = z.object({
    title: z.string(),
    bodyHtml: z.string(),
});

export const InvalidLinkContentSchema = z.object({
    title: z.string(),
    bodyHtml: z.string(),
});

export const WelcomeBackContentSchema = z.object({
    title: z.string(),
    bodyHtml: z.string(),
});

export type WelcomeContent = z.infer<typeof WelcomeContentSchema>;

export const ContentPropertiesSchema = z.object({
    ...HeaderContentSchema.shape,

    ...WelcomeContentSchema.shape,
    ...QuoteStartContentSchema.shape,
    ...QuoteResultsContentSchema.shape,
    ...PersonalInfoContentSchema.shape,
    ...BeneficiariesContentSchema.shape,
    ...AddresseeContentSchema.shape,
    ...HealthStatementContentSchema.shape,
    ...PaymentInfoContentSchema.shape,
    ...ReviewContentSchema.shape,
    ...ConsentContentSchema.shape,
    ...ProcessingContentSchema.shape,
    ...ApprovedContentSchema.shape,
    ...CheckBackContentSchema.shape,
    ...OopsSorryContentSchema.shape,
    ...DeclinedContentSchema.shape,
    ...UnableToFinishContentSchema.shape,
    ...SessionExpiredContentSchema.shape,
    ...InvalidLinkContentSchema.shape,
    ...WelcomeBackContentSchema.shape,
});
