import { DirectTermApplicationState } from "state/RootSchema";
import { toSingleLetterFromLowerCaseGender } from "./toSingleLetterFromLowerCaseGender";

import { post_Submit } from "services/online-application-service.openapi.zod";
import { z } from "zod";

const BodySchema = post_Submit.parameters.shape.body;
type DirectTermSubmissionRequestBody = z.infer<typeof BodySchema>;
type SecondaryAddressee = z.infer<typeof post_Submit.parameters.shape.body.shape.secondaryAddressee>;

export function transformSubmissionData(rootData: DirectTermApplicationState): DirectTermSubmissionRequestBody {
    const data = rootData.application;

    const replaceMentPolicy = data.willReplacePolicy
        ? {
              replacementIndicator: data.willReplacePolicy,
              replacementCompany: data.policyToReplace.company,
              replacementAccount: data.policyToReplace.policyNumber || undefined,
          }
        : {
              replacementIndicator: data.willReplacePolicy,
          };

    const addressees = data.designatedSecondaryAddressee
        ? {
              hasSecondaryAddressee: data.designatedSecondaryAddressee,
              secondaryAddressee: {
                  firstName: data.secondaryAddressee?.firstName,
                  lastName: data.secondaryAddressee?.lastName,
                  phone: data.secondaryAddressee?.phone || undefined,
                  address: {
                      addressLine1: data.secondaryAddressee?.addressLine1,
                      addressLine2: data.secondaryAddressee?.addressLine2 || undefined,
                      city: data.secondaryAddressee?.city,
                      state: data.secondaryAddressee?.state,
                      zipCode: data.secondaryAddressee?.zipCode,
                  },
              } satisfies SecondaryAddressee,
          }
        : {
              hasSecondaryAddressee: data.designatedSecondaryAddressee,
          };

    const additionalData = [
        {
            key: "applicantType",
            value: data.applicantType,
        },
    ];

    return {
        applicationID: data.applicationID,
        policyNumber: data.policyNumber,

        faceAmount: data.selectedCoverageAmount,
        modalPremiumAmount: data.selectedCoveragePremium,

        ...replaceMentPolicy,

        termPeriod: 20, // ???
        applicant: {
            firstName: data.firstName,
            lastName: data.lastName,
            birthDate: data.dateOfBirth,
            phone: data.phone,
            phoneType: data.phoneType, // Add translation here, not likely to be the same
            address: {
                addressLine1: data.addressLine1,
                addressLine2: data.addressLine2 || undefined,
                city: data.city,
                state: data.state,
                zipCode: data.zipCode,
                country: "USA",
            },
            weight: data.weight,
            gender: toSingleLetterFromLowerCaseGender(data.gender),
            aaaMember: true,
            keyCode: rootData.application.keyCode,
            aaaMemberNumber: rootData.application.aaaMemberNumber,
            email: data.email,
            height: data.height?.feet * 12 + data.height?.inches,
            nicotineUse: data.hasUsedNicotineLastYear,
            treatment: data.hasSevereMedicalConditions,
            diagnosis: data.hadDiagnosticTesting,
        },

        beneficiaries: [
            {
                firstName: data.beneficiary?.firstName,
                middleInitial: data.beneficiary?.middleInitial || undefined,
                lastName: data.beneficiary?.lastName,
                relationship: data.beneficiary?.relationship,
                percentage: data.beneficiary?.percentage,
                beneficiaryType: "primary",
            },
        ],

        ...addressees,

        paymentToken: data.paymentToken,
        acceptanceConfirmed: data.agreeToConsent,
        hannoverID: data.hannoverId,
        campaign: {
            campaignType: "CAMPAIGNTYPE_DIRECTMAIL",
            productCode: rootData.clubSpecificData.planCode,
            clubCode: rootData.clubSpecificData.clubCode,
            leadSource: rootData.campaign.leadSource || undefined,
            campaignName: "DIR",
            campaignCode: rootData.campaign.campaignCode,
            offer: data.coverageAmounts,
        },
        offerPurl: rootData.campaign.offerPurl,
        signedTimestamp: new Date().toISOString(),
        applicationSubmissionTimestamp: new Date().toISOString(),

        additionalData,
    };
}
